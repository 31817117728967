<template>
  <router-link to="/account/login"
    ><img src="../../../assets/images/ommed_logo.svg" class="logo"
  /></router-link>
  <div class="account-form info-form">
    <div class="form infos fadeinleft animation-duration-300">
      <div class="card-title">
        <h5 class="my-0 title-color">Recuperar acesso</h5>
      </div>
      <div class="card-body">
        <div class="recover-text mt-3 mb-3">
          <p>
            Digite o e-mail vinculado a sua conta, você receberá <br />
            uma mensagem com as instruções para recuperar seu <br />
            acesso e redefinir sua senha
          </p>
        </div>
        <div>
          <InputText
            class="std-input"
            type="text"
            v-model="email"
            placeholder="Informe seu e-mail"
          />
        </div>
        <small v-if="error['email']" class="p-invalid p-mt-2"
          ><i class="pi pi-times-circle p-ml-1" />{{ error["email"][0] }}
        </small>
        <div class="d-flex jc-center mt-5 mb-2">
          <Button class="gradient-btn" label="Continuar" @click="recoverPassword" />
        </div>
        <div class="link text-bold mt-5" v-if="outEmail">
          <a class="color-gray__3" href="/account/recover/searchAccount"
            >Localizar conta com outros dados</a
          >
        </div>
        <div class="link mb-4">
          <a href="/account/login">Cancelar</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import router from "../../../router";
import { FindUserAccountRequest, SendPasswordRecoveryCodeToEmailRequest } from "../../core/grpc/generated/professionalAccount_pb";
import professionalAcountService from "../../core/services/professionalAccountService";

export default {
  setup() {
    let email = ref("");
    let outEmail = ref(false);
    let data = ref();
    let error = ref([]);

    const recoverPassword = async () => {
      const request = new SendPasswordRecoveryCodeToEmailRequest();
      request.setEmail(email.value);
      // console.log(request);
      localStorage.setItem("user", email.value);
      localStorage.setItem("email", true);
      const response =
        await professionalAcountService.sendPasswordRecoveryCodeToEmail(
          request
        );
      // console.log(response);
      if (response.success == true) {
        await findAccount();
        router.push("/account/recover/change_password");
      } else {
        error.value = JSON.parse(response.data);
        outEmail.value = true;
        // Object.keys(error).forEach((item) => {
        //   toast.add({
        //     severity: "error",
        //     summary: item,
        //     detail: error[item][0],
        //     life: 3000,
        //   });
        // });
      }
    };

    const findAccount = async () => {
      const request = new FindUserAccountRequest();
      request.setUser(email.value);
      const response = await professionalAcountService.findUserAccount(request);
      if (response.success == true) {
        data.value = JSON.parse(response.data);
        localStorage.setItem('userId', data.value.id);
      } else {
        error.value = JSON.parse(response.data);
        console.log(error.value);
      }
    };

    return {
      email,
      outEmail,
      error,
      recoverPassword,
    };
  },
};
</script>

<style lang="scss">
@import "../../app/css/Responsive.scss";
.account-form {
  min-height: 80vh;
  .std-input {
    border-radius: 8px !important;
  }

  .form {
    @include responsive(650px, major) {
      width: 95vw;
    }
  }
}

hr {
  position: relative;
  left: -40px;
  width: 480px;
}

.link {
  margin: 15px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.recover-form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.recover-text {
  margin: 15px auto;
  text-align: center;
  display: block;
  color: #828282;
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
}

.button-confirm {
  margin-top: 25px;
  border-radius: 8px;
}

.gradient-btn {
  width: 317px !important;
}
</style>
